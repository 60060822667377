<template>
	<EditorObjectPin @done="onDone"></EditorObjectPin>
</template>
<script>
import EditorObjectPin from './editorObjectPin';

export default {
	components: { EditorObjectPin },
	methods: {
		onDone({ floorId }) {
			// console.log('roomId on page roomedit');
			// console.log(roomId);
			// this.$router.replace({ name: 'roomDetail', params: { id: roomId } });
			this.$router.replace({ name: 'floorDetail', params: { id: floorId } });
		},
	},
};
</script>

<style scoped></style>
